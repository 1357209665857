// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $;
window.$ = $;

// import "slick-carousel/slick/slick";

require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("slick")

require("bootstrap")
require("bootstrap-select")
require("packs/slick/slick.min")
// require("packs/bootstrap/js/bootstrap.min.js")
// require("packs/bootstrap/js/popper.min.js")
// require("packs/selectpiker/js/bootstrap-select.min.js")


require("packs/scripts.min")
require('packs/validation/jquery.validationEngine.min')
require("packs/validation/jquery.validationEngine-ua")
require("packs/bucket")
require("packs/lining/lining.min.js")
require("packs/lazyload/jquery.lazyload.js")
// require('inputmask')
require("packs/maskedinput/jquery.maskedinput.min.js")
require("packs/reviews")

// import "../stylesheets/application"

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  var $seo_block_js = $("#seo_block_js");
  if ($seo_block_js.length){
    // console.log('$("#seo_block_js") Present !!!');

    var $seo_description = $(".seo_description_js",$seo_block_js)[0];

    var seoLining = lining($seo_description, {
      'autoResize': true
    });

    lining.util.on($seo_description, 'afterlining', function () {
      $(".seo-full-size").addClass("active");
      var lines = $('text-line').length;
      // seoLining.unlining();

      // console.log("lines",lines);
      if (lines > 10){
        $seo_block_js.addClass("show_more_btn collapse-description");
        $(".line:lt(10)",$seo_block_js).addClass("visible_lines").eq(9).addClass("doter_line");
        $(".collapse_seo_btn_js").on("click",function (e) {
          var $this = $(this);
          if ($this.hasClass("active")) {
            $this.removeClass("active").closest("#seo_block_js").find(".seo_description_js").addClass("mini");
          }else{
            $this.addClass("active").closest("#seo_block_js").find(".seo_description_js").removeClass("mini");
          }
        })
      }
    }, false);
  }

  $("img").lazyload();

  $('.hide-chat').each(function(){
    $(this).attr('src',$(this).data('original'));
  });

  // On before slide change
  $(".reviews-container,.recommended-slick,.recommended-slick-1").on('beforeChange', function(event, slick){
    $(this).find('img[src^="data:image"]').each(function () {
      $(this).attr("src",$(this).data("original"));
    });
  });

  function stopVideo(element) {
    var frame = element;
    if (frame == undefined) return;
    frame.contentWindow.postMessage(
        '{"event":"command","func":"stopVideo","args":""}',
        '*');
  }

  $('.carousel').on('slide.bs.carousel', function (e) {
    $(this).find('img[src^="data:image"]:not([alt="arr"])').each(function () {
      $(this).attr("src",$(this).data("original"));
    });

    var next_slide = $(e.target).find('.carousel-item').eq(e.to),
        image = next_slide.find('.img-fluid'),
        iframe_wrap = next_slide.find('.iframe_wrap'),
        current_slide = $(e.target).find('.carousel-item').eq(e.from);

    if (image.is('.iframe_image')){
      image.hide();
      iframe_wrap.show();
    }else{
      stopVideo(current_slide.find('iframe')[0]);
    }

  });

  $('.modal_bucket').on('show.bs.modal', function (e) {
    $(this).find('img.for_lazy[src^="data:image"]:not([alt="arr"])').each(function () {
      $(this).attr("src",$(this).data("original"));
    });
  })

  $(".iframe_image").each(function () {
    $(this).attr("src",$(this).data("original")).on('load', function (e) {
      var $carousel_item = $(this).closest('.carousel-item');
      var $iframe_wrap = $(this).next('.iframe_wrap');

      $carousel_item.addClass("for_detect_image_size");
      $iframe_wrap.html($iframe_wrap.data('iframe')).find("iframe").attr({'width':$(this).width(), 'height':$(this).height()});
      $carousel_item.removeClass("for_detect_image_size");

    });
  });

  if ($(".w_pagination_item.next_page.link") && !$(".mobile_paginate_btn").hasClass("last_page")){
    $(".mobile_paginate_btn").attr("href",$(".w_pagination_item.next_page.link").attr("href") + "&remote=true").addClass("active");
  }

  $(window).trigger('resize');
  $("body").addClass('test_it');

  // $.ajaxSetup({
  //   headers: {
  //     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  //   },
  //   beforeSend: function ( xhr ) {
  //     xhr.setRequestHeader( 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content') );
  //   }
  // });

  // $.fn.setCursorPosition = function(pos) {
  //   if ($(this).get(0).setSelectionRange) {
  //     $(this).get(0).setSelectionRange(pos, pos);
  //   } else if ($(this).get(0).createTextRange) {
  //     var range = $(this).get(0).createTextRange();
  //     range.collapse(true);
  //     range.moveEnd('character', pos);
  //     range.moveStart('character', pos);
  //     range.select();
  //   }
  // };
  //
  // $('#order_phone').click(function(){
  //   $(this).setCursorPosition(4);
  // }).mask("+380999999999");



  $('#order_phone').mask("+380999999999");

  $(document).click(function(event) {
    var $target = $(event.target);
    if (!$target.closest('.catalog_item_js.show').length) {
      $('.category_item_js').removeClass('show');
    }
  });

  $('.catalog_item_js:not(.show), .catalog_item_js:not(.show)>a').click(function(event) {
    var $target = $(event.target);
    if (!$target.closest('.catalog_item_js.show').length) {
      $('.category_item_js').removeClass('show');
    }
  });

  $('.category_item_js').click(function (e) {
    e.preventDefault();
    var active_item = $(this).hasClass('show');
    $('.category_item_js').removeClass('show');
    if(!active_item){
      $(this).addClass('show');
    }
    return false;
  })

  $('.catalog_menu_btn').click(function (e) {
    e.preventDefault();
    $('.main_navbar_toggler').click();
    return false;
  })
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
